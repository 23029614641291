export function formatBytes(size: number): string {
    const KB = 1024
    const MB = KB * 1024
    const GB = MB * 1024
    const TB = GB * 1024

    if (size < KB) {
        return size.toFixed(2) + ' B'
    } else if (size < MB) {
        return (size / KB).toFixed(2) + ' KB'
    } else if (size < GB) {
        return (size / MB).toFixed(2) + ' MB'
    } else if (size < TB) {
        return (size / GB).toFixed(2) + ' GB'
    } else {
        return (size / TB).toFixed(2) + ' TB'
    }
}
