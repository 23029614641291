import { Accordion, Button, Card, Form, InputGroup, OverlayTrigger, Popover, Spinner } from 'react-bootstrap'
import {
    AuthenticatedUser,
    FrameEntry,
    Project,
    RecordingAnnotation,
    RecordingSession,
    UserBillableUnitInfo,
} from '../../../../api/CloudApi/types'
import SignalDataBars from './SignalDataBar'
import { toast } from 'react-toastify'
import { formattedToastMessage } from '../../../../utils/toast'
import { CopyIcon, ShareIcon } from '../../../../assets/Icons'
import {
    ProductAnalyticsContext,
    createAnalyticsTrackingKey,
    ProductAnalyticsProps,
    useProductAnalyticsClient,
} from '../../../../utils/ProductAnalytics'
import { useEffect, useState } from 'react'
import AnnotationsOverview from './AnnotationsOverivew'
import { AggregatedFrameEntryPerNamespace } from '../Types'

interface OverviewAccordionProps {
    availableFrameEntries: Array<FrameEntry>
    availableAnnotations: Array<RecordingAnnotation>
    shareableDashboardLink: string | undefined
    currentUser: AuthenticatedUser | undefined
    currentProject: Project | undefined
    currentBillableUnit: UserBillableUnitInfo | undefined
    currentRecordingSession: RecordingSession | undefined
    aggregatedFrameEntries: Array<AggregatedFrameEntryPerNamespace>
    safeFetchAndSetAvailableAnnotations: () => Promise<Array<RecordingAnnotation>>
}

const BACKEND_MAX_URL_LENGTH = 4096
const COPY_MESSAGE_RESET_TIMER = 2000

const AnalyticsActions = {
    SHARE_LINK_ERROR: createAnalyticsTrackingKey(
        ProductAnalyticsContext.RD_VISUALIZE_TAB,
        'CopyShareableLinkUrlTooLong'
    ),
    EXPAND_OR_COLLAPSE: createAnalyticsTrackingKey(
        ProductAnalyticsContext.RD_VISUALIZE_TAB,
        'ExpandOrCollapseOverview'
    ),
    COPY_SHAREABLE_LINK: createAnalyticsTrackingKey(ProductAnalyticsContext.RD_VISUALIZE_TAB, 'CopyShareableLink'),
}

export default function OverviewAccordion(props: OverviewAccordionProps) {
    const productAnalyticsClient = useProductAnalyticsClient({
        user: props.currentUser,
        billableUnit: props.currentBillableUnit,
    } as ProductAnalyticsProps)

    const [showPopover, setShowPopover] = useState<boolean>(false)
    const [isUrlCopied, setIsUrlCopied] = useState<boolean>(false)

    useEffect(() => {
        if (isUrlCopied) {
            const timer = setTimeout(() => setIsUrlCopied(false), COPY_MESSAGE_RESET_TIMER)
            return () => clearTimeout(timer)
        }
    }, [isUrlCopied])

    const getCopyIcon = (isCopied: boolean) => {
        if (isCopied) {
            return 'Copied!'
        }
        return <CopyIcon sx={{ fontSize: 17 }} />
    }

    const copyBrokerUrl = () => {
        if (props.shareableDashboardLink !== undefined) {
            navigator.clipboard.writeText(props.shareableDashboardLink)
            setIsUrlCopied(true)
        }
    }

    const popover = (
        <Popover id="popover-basic" className="border-0 remotive-primary-5-background shadow" style={{ minWidth: 350 }}>
            <Popover.Body className="pb-2" style={{ minWidth: 350 }}>
                <div className="mb-3 text-center">
                    <div className="d-flex justify-content-center remotive-font-md flex-column">
                        <>
                            <p className="text-start m-0 lexend-regular">Shareable link</p>
                            <InputGroup size="sm" className="mb-1">
                                <Form.Control
                                    className="bg-white remotive-primary-40-border"
                                    value={
                                        props.shareableDashboardLink !== undefined
                                            ? props.shareableDashboardLink
                                            : 'N/A'
                                    }
                                    disabled={true}
                                />
                                <Button
                                    className="remotive-btn-primary"
                                    onClick={() => {
                                        copyBrokerUrl()
                                        productAnalyticsClient.track(AnalyticsActions.COPY_SHAREABLE_LINK)
                                    }}
                                >
                                    {getCopyIcon(isUrlCopied)}
                                </Button>
                            </InputGroup>
                        </>
                    </div>
                </div>
            </Popover.Body>
        </Popover>
    )

    const overviewAccordion = () => {
        const isFrameDataAvailable = props.availableFrameEntries.length > 0
        return (
            <>
                <Card
                    className="w-100 shadow-sm rounded-4 border-0 text-start mb-1"
                    id="recording-details-playback-accordion"
                >
                    <Card.Body className="p-0">
                        <Accordion defaultActiveKey={'0'} aria-expanded="false" flush className="mx-2 p-1 py-0">
                            <Accordion.Item eventKey="1" className="mx-0 px-0">
                                <Accordion.Header
                                    className="mx-0 p-0 d-flex justify-content-between"
                                    onClick={() => productAnalyticsClient.track(AnalyticsActions.EXPAND_OR_COLLAPSE)}
                                >
                                    <div className="m-1 w-100">
                                        <div className="">
                                            <p className="m-0 p-0">
                                                <b className="me-2">
                                                    {isFrameDataAvailable ? (
                                                        'Overview'
                                                    ) : (
                                                        <span className="p-0 m-0">
                                                            <Spinner className="me-2" size="sm" />
                                                            Loading...
                                                        </span>
                                                    )}
                                                </b>
                                            </p>
                                            <p className="remotive-font-sm text-secondary m-0 mt-1 p-0">
                                                Expand to see more
                                            </p>
                                        </div>
                                    </div>
                                    <div className="">
                                        <div className="d-flex justify-content-end">
                                            <div className="me-3">
                                                {props.shareableDashboardLink !== undefined && (
                                                    <div
                                                        onClick={(e) => {
                                                            e.stopPropagation() // This will stop the accordion from toggling on the event
                                                        }}
                                                        className="btn remotive-btn-primary remotive-btn-md border-0"
                                                    >
                                                        <OverlayTrigger
                                                            trigger="click"
                                                            rootClose
                                                            show={showPopover}
                                                            onToggle={(newState: boolean) => {
                                                                if (
                                                                    props.shareableDashboardLink &&
                                                                    props.shareableDashboardLink.length >=
                                                                        BACKEND_MAX_URL_LENGTH
                                                                ) {
                                                                    productAnalyticsClient.track(
                                                                        AnalyticsActions.SHARE_LINK_ERROR
                                                                    )
                                                                    toast.error(
                                                                        formattedToastMessage(
                                                                            'Dashboard error',
                                                                            'The current dashboard is too large to be able to share via a url. Please remove some visualizations and try again.'
                                                                        ),
                                                                        { autoClose: 15_000 }
                                                                    )
                                                                } else {
                                                                    setShowPopover(newState)
                                                                }
                                                            }}
                                                            placement="top"
                                                            overlay={popover}
                                                        >
                                                            <div className="d-flex align-items-center">
                                                                <ShareIcon sx={{ fontSize: 16 }} />
                                                                <p className="m-0 mx-2 d-none d-lg-block">Share</p>
                                                            </div>
                                                        </OverlayTrigger>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body className="mx-0 px-0 pt-0">
                                    <div className="align-items-center w-100">
                                        {props.availableFrameEntries?.length > 0 ? (
                                            <>
                                                <SignalDataBars aggregatedFrameEntries={props.aggregatedFrameEntries} />
                                            </>
                                        ) : (
                                            <>Loading...</>
                                        )}
                                    </div>
                                    {props.availableAnnotations.length > 0 && (
                                        <div>
                                            <AnnotationsOverview
                                                currentUser={props.currentUser}
                                                currentProject={props.currentProject}
                                                currentRecordingSession={props.currentRecordingSession}
                                                safeFetchAndSetAvailableAnnotations={
                                                    props.safeFetchAndSetAvailableAnnotations
                                                }
                                                availableAnnotations={props.availableAnnotations}
                                            />
                                        </div>
                                    )}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Card.Body>
                </Card>
            </>
        )
    }

    return overviewAccordion()
}
