import { ColDef, ValueGetterParams } from 'ag-grid-community'
import { ValueFormatterParams } from 'ag-grid-enterprise'
import { GridRow } from './types'
import { number } from 'yup'
import { PlottableSignalEntry } from '../../Types'

interface ColumnProperties {
    startTime: number
    renderer: string
}

export function tableHeaders(props: ColumnProperties): ColDef[] {
    function formatDurationExact(millis: number): string {
        const millisecondsInSecond = 1000
        const millisecondsInMinute = 60 * millisecondsInSecond
        const millisecondsInHour = 60 * millisecondsInMinute

        if (millis < millisecondsInSecond) {
            const seconds = millis / millisecondsInSecond
            return `0.${seconds.toFixed(3).split('.')[1]} s` // Subsecond precision
        } else if (millis < millisecondsInMinute) {
            const seconds = millis / millisecondsInSecond
            return `${seconds.toFixed(3)} s` // Seconds with milliseconds precision
        } else if (millis < millisecondsInHour) {
            const minutes = Math.trunc(millis / millisecondsInMinute)
            const remainingMillis = millis % millisecondsInMinute
            const seconds = remainingMillis / millisecondsInSecond
            return `${minutes}m ${seconds.toFixed(3)} s` // Exact minutes and seconds
        } else {
            const hours = Math.trunc(millis / millisecondsInHour)
            const remainingMillis = millis % millisecondsInHour
            const minutes = remainingMillis / millisecondsInMinute
            return `${hours}h ${minutes.toFixed(3)}m` // Exact hours and minutes
        }
    }

    // Custom Renderer for Toggle Button

    return [
        {
            headerName: 'Timestamp',
            colId: 'timestamp',
            field: 'timestamp',
            sortable: true,
            sort: 'asc',
            filter: 'agNumberColumnFilter',
            width: 300,
            valueFormatter: (params: ValueFormatterParams<GridRow, any>) => {
                return params.data?.timestamp ? new Date(params.data.timestamp).toISOString() : ''
            },
        },
        {
            headerName: 'Relative time',
            colId: 'relativeTime',
            field: 'relativeTime',
            sortable: false,
            //sort: 'asc',
            valueFormatter: (params: ValueFormatterParams<GridRow>) => {
                if (!params.value) return ''
                return formatDurationExact(params.value)
            },
        },
        /*{
            field: 'active',
            headerName: 'Active',
            cellRenderer: props.renderer, // Reference the custom renderer
            sortable: false,
            filter: false,
        },*/
        {
            headerName: 'Time diff',
            field: 'diff',
            sortable: false, // Filter and sort is not possible since its dynamic   ally created
            width: 80,
            valueGetter: (params: ValueGetterParams<GridRow>) => {
                if (!params.data || !params.node) return null
                const currentRowIndex = params.node.rowIndex
                if (!currentRowIndex) {
                    return null
                }
                // Skip calculation if the current row is not a parent
                if (params.data.type === 'signal') return null

                // Find the previous parent row
                for (let i = currentRowIndex - 1; i >= 0; i--) {
                    const previousRowNode = params.api.getDisplayedRowAtIndex(i)
                    if (previousRowNode && previousRowNode.data && previousRowNode.data.type !== 'signal') {
                        const currentTimestamp = params.data.timestamp!
                        const previousTimestamp = previousRowNode.data.timestamp!

                        // Calculate and return the time difference in microseconds
                        return (currentTimestamp - previousTimestamp) / 1000 // Convert ms to µs
                    }
                }

                // No previous parent row found
                return 'N/A'
            },
        },
        {
            headerName: 'FrameID',
            field: 'id',
            sortable: false,
            filter: 'agMultiColumnFilter', // Use agMultiColumnFilter
            filterParams: {
                filters: [
                    { filter: 'agNumberColumnFilter' }, // Add number filter
                    { filter: 'agSetColumnFilter' }, // Add set filter
                ],
            },

            width: 80,
        },
        {
            headerName: 'Data',
            field: 'hex',
            sortable: false,
            filter: true,
            width: 300,
            minWidth: 150,
            valueFormatter: (params) => {
                const hex: string = params.value || ''
                return (
                    hex
                        .toUpperCase()
                        .match(/.{1,2}/g)
                        ?.join(' ') || ''
                ) // Add spaces every 2 characters
            },
        },
        {
            headerName: 'Value',
            field: 'value',
            sortable: true,
            filter: 'agMultiColumnFilter', // Use agMultiColumnFilter
            filterParams: {
                filters: [
                    { filter: 'agNumberColumnFilter' }, // Add number filter
                ],
            },
        },
        {
            headerName: 'Channel',
            field: 'namespace',
            sortable: false,
            filter: 'agMultiColumnFilter', // Use agMultiColumnFilter
            filterParams: {
                filters: [
                    { filter: 'agNumberColumnFilter' }, // Add number filter
                    { filter: 'agSetColumnFilter' }, // Add set filter
                ],
            },
        },
        {
            headerName: 'Sender',
            field: 'sender',
            sortable: false,
            filter: 'agMultiColumnFilter', // Use agMultiColumnFilter
            filterParams: {
                filters: [
                    { filter: 'agTextColumnFilter' }, // Add number filter
                    { filter: 'agSetColumnFilter' }, // Add set filter
                ],
            },
        },

        {
            headerName: 'Cycle time',
            field: 'cycleTime',
            sortable: false,
            filter: 'agMultiColumnFilter', // Use agMultiColumnFilter
            filterParams: {
                filters: [
                    { filter: 'agNumberColumnFilter' }, // Add number filter
                    { filter: 'agSetColumnFilter' }, // Add set filter
                ],
            },
        },
        {
            headerName: 'Unit',
            field: 'unit',
            sortable: false,
            filter: 'agMultiColumnFilter', // Use agMultiColumnFilter
            filterParams: {
                filters: [
                    { filter: 'agTextColumnFilter' }, // Add number filter
                    { filter: 'agSetColumnFilter' }, // Add set filter
                ],
            },
        },
        {
            headerName: 'Min',
            field: 'min',
            sortable: false,
            filter: 'agMultiColumnFilter', // Use agMultiColumnFilter
            filterParams: {
                filters: [
                    { filter: 'agNumberColumnFilter' }, // Add number filter
                    { filter: 'agSetColumnFilter' }, // Add set filter
                ],
            },
        },
        {
            headerName: 'Max',
            field: 'max',
            sortable: false,
            filter: 'agMultiColumnFilter', // Use agMultiColumnFilter
            filterParams: {
                filters: [
                    { filter: 'agNumberColumnFilter' }, // Add number filter
                    { filter: 'agSetColumnFilter' }, // Add set filter
                ],
            },
        },
    ]
}
