import Dropzone, { DropzoneRef, FileRejection, DropEvent, FileWithPath } from 'react-dropzone'
import jszip from 'jszip'
import { useState, forwardRef } from 'react'
import { toast } from 'react-toastify'
import { Project, RecordingSession } from 'src/api/CloudApi/types'
import {
    createAnalyticsTrackingKey,
    ProductAnalyticsProperties,
    ProductAnalyticsProps,
    useProductAnalyticsClient,
} from 'src/utils/ProductAnalytics'
import { formattedToastMessage } from 'src/utils/toast'
import { CloudUploadIcon } from 'src/assets/Icons'
import InlineFileUploadProgressContainer from 'src/components/InlineFileUploadProgressContainer'
import { UserRecordingSessionContext } from 'src/types/Context'

interface TransformationFileDropzoneProps {
    userRecordingSessionContext: UserRecordingSessionContext
    productAnalyticsProperties: ProductAnalyticsProperties

    hasPermissionToUpload: boolean

    isUploading: boolean
    uploadPercent: number

    transformationFilesPreparedForUpload: Array<FileWithPath>
    setTranformationFilesPreparedForUpload: (files: Array<FileWithPath>) => void
}

const INVALID_FILES = ['.DS_STORE', '__MACOSX']

export default forwardRef(function TransformationFileDropzone(
    props: TransformationFileDropzoneProps,
    refFromParent: React.Ref<DropzoneRef>
) {
    const [isHoveringDropzone, setIssHoveringDropzone] = useState<boolean>(false)

    const productAnalyticsClient = useProductAnalyticsClient({
        user: props.productAnalyticsProperties?.currentUser,
        billableUnit: props.productAnalyticsProperties?.currentBillableUnit,
    } as ProductAnalyticsProps)

    const AnalyticsActions = {
        UPLOAD_CONFIGURATION: createAnalyticsTrackingKey(
            props.productAnalyticsProperties.productAnalyticsContext,
            'PrepareToUploadConfiguration'
        ),
        UPLOAD_CONFIGURATION_UNKNOWN_ERROR: createAnalyticsTrackingKey(
            props.productAnalyticsProperties.productAnalyticsContext,
            'PrepareToUploadConfigurationFailedUnknownError'
        ),
    }

    const clickableUploadFileArea = () => {
        return (
            <div className="d-flex p-1 justify-content-center align-items-center h-100">
                <p className="m-0 remotive-font-md remotive-primary-70-color text-center">
                    <CloudUploadIcon className="me-2" sx={{ fontSize: 35 }} />{' '}
                    {isHoveringDropzone ? (
                        <>
                            Drop the transformations folder to <b>upload it!</b>
                        </>
                    ) : (
                        <>
                            Drag a folder here or click to <b>upload a transformations folder</b>
                        </>
                    )}
                </p>
            </div>
        )
    }

    const separateFilesAndCompressedFiles = <T extends File>(acceptedFiles: T[]): [T[], T[]] => {
        return acceptedFiles.reduce(
            ([nonZipped, zipped], file) => {
                if (file.name.endsWith('.zip')) {
                    zipped.push(file)
                } else {
                    nonZipped.push(file)
                }
                return [nonZipped, zipped]
            },
            [[], []] as [T[], T[]] // Initial values of nonZipped and zipped
        )
    }

    const decompressFiles = async <T extends File>(compressedFiles: T[]): Promise<FileWithPath[]> => {
        try {
            const zip = new jszip()
            const decompressedFiles = await Promise.all(
                compressedFiles.map(async (file) => {
                    const zipContent = await zip.loadAsync(file)
                    const files: FileWithPath[] = []
                    await Promise.all(
                        Object.keys(zipContent.files).map(async (fileName) => {
                            if (!INVALID_FILES.some((it) => fileName.includes(it))) {
                                const zipEntry = zipContent.files[fileName]
                                if (!zipEntry.dir) {
                                    const content = await zipEntry.async('blob')
                                    const fileWithPath: FileWithPath = Object.assign(
                                        new File([content], fileName, { type: content.type }),
                                        { path: fileName }
                                    )
                                    files.push(fileWithPath)
                                }
                            }
                        })
                    )

                    return files
                })
            )

            return decompressedFiles.flat()
        } catch (error: any) {
            toast.error(formattedToastMessage("Unzip Error", "Failed to unzip file, make sure that it is a valid zip file."))
        }
        return []
    }

    const onFileDrop = async <T extends File>(
        acceptedFiles: T[],
        fileRejections: FileRejection[],
        event: DropEvent,
        project: Project,
        recordingSession: RecordingSession
    ) => {
        setIssHoveringDropzone(false)

        if (acceptedFiles.length > 0) {
            productAnalyticsClient.track(AnalyticsActions.UPLOAD_CONFIGURATION)
            const [files, compressedFiles] = separateFilesAndCompressedFiles(acceptedFiles)
            const decompressedFiles = await decompressFiles(compressedFiles)
            const preparedFiles = [...files, ...decompressedFiles]

            props.setTranformationFilesPreparedForUpload(preparedFiles)
        } else {
            productAnalyticsClient.track(AnalyticsActions.UPLOAD_CONFIGURATION_UNKNOWN_ERROR)
            toast.error(formattedToastMessage('Upload failed', `Failed to upload folder due to an unknown error.`))
        }
    }

    const transformationsFolderDropzone = (project: Project, recordingSession: RecordingSession) => {
        return (
            <>
                <Dropzone
                    useFsAccessApi={false}
                    multiple={true}
                    ref={refFromParent}
                    disabled={props.isUploading}
                    onDragEnter={() => {
                        setIssHoveringDropzone(true)
                    }}
                    onDragLeave={() => {
                        setIssHoveringDropzone(false)
                    }}
                    onDrop={(acceptedFiles, fileRejections, event) =>
                        onFileDrop(acceptedFiles, fileRejections, event, project, recordingSession)
                    }
                >
                    {({ getRootProps, getInputProps }) => (
                        <div
                            className={`dropzone rounded-2 ${
                                isHoveringDropzone ? 'remotive-primary-10-background' : 'remotive-primary-0-background'
                            }`}
                            style={{
                                height: '94px',
                            }}
                            {...getRootProps()}
                        >
                            <input
                                {...getInputProps({
                                    // Cast to bypass TypeScript error
                                    ...(getInputProps() as any),
                                    webkitdirectory: 'true',
                                    directory: 'true',
                                })}
                            />
                            <div className="w-100">
                                {props.isUploading ? (
                                    <InlineFileUploadProgressContainer
                                        inProgressText="Uploading transformations folder..."
                                        finishedText="Upload complete, finishing up..."
                                        currentPercent={props.uploadPercent}
                                    />
                                ) : (
                                    clickableUploadFileArea()
                                )}
                            </div>
                        </div>
                    )}
                </Dropzone>
            </>
        )
    }

    const component = () => {
        if (props.hasPermissionToUpload) {
            return transformationsFolderDropzone(
                props.userRecordingSessionContext.currentProject,
                props.userRecordingSessionContext.currentRecordingSession
            )
        }
        return <></>
    }

    return component()
})
