import { ReactElement, useEffect, useRef, useState } from 'react'
import { Alert, Container, Dropdown, Form } from 'react-bootstrap'
import Dropzone, { DropzoneRef } from 'react-dropzone'
import {
    AuthenticatedUser,
    FileObject,
    FileObjectsContainer,
    FileObjectTypeEnum,
    Project,
    UserBillableUnitInfo,
} from '../../api/CloudApi/types'
import { BreadCrumb, BreadCrumbs } from '../../types/BreadCrumbs'
import { Link, useSearchParams } from 'react-router-dom'
import NavigationBar from '../../components/navigation/NavigationBar'
import { CURRENT_PATH_PARAM } from '../../utils/queryParams'
import RLCard from '../../components/cards/RLCard'
import { PageDetails } from '../../utils/pageDetails'
import {
    CloudUploadIcon,
    CreateFolderIcon,
    DeleteIcon,
    DirectoryIcon,
    DownloadIcon,
    GenericFileIcon,
    StorageIcon,
    GridViewIcon,
    ImageFileIcon,
    ImportExportIcon,
    ListViewIcon,
    MoreVerticalIcon,
    RefreshIcon,
    ShareIcon,
    SoundFileIcon,
    TextFileIcon,
    UploadIcon,
    VideoFileIcon,
    ViewIcon,
    ZippedFileIcon,
} from '../../assets/Icons'
import { hasPermission, Permission } from '../../utils/permission'
import { OrganisationRoutes } from '../../Routes'
import CloudApi from '../../api/CloudApi'
import { ComponentState } from '../../types/ComponentState'
import LoadingContainer from '../../components/LoadingContainer'
import {
    COMMON_COMPRESSED_FILE_TYPES,
    COMMON_IMAGE_FILE_TYPES,
    COMMON_SOUND_FILE_TYPES,
    COMMON_TEXT_FILE_TYPES,
    COMMON_VIDEO_FILE_TYPES,
} from './FileFormats'
import WarningRounded from '@mui/icons-material/WarningRounded'
import { toast } from 'react-toastify'
import { formattedToastMessage } from '../../utils/toast'
import ErrorContainer from '../../components/ErrorContainer'
import NotFoundContaner from '../../components/NotFoundContainer'
import CliHintContainer, { CliHint } from '../../components/CliHintContainer'
import { ConfirmDialogProperties } from '../../types/ConfirmDialogProperties'
import FilePreviewModal from './FilePreviewModal'
import { ALLOWED_RECORDING_FILE_NAME_ENDINGS } from '../../utils/files'
import { ProductAnalyticsContext, ProductAnalyticsProperties } from '../../utils/ProductAnalytics'
import CreateFolderModal from './CreateFolderModal'
import { AxiosProgressEvent, AxiosRequestConfig } from 'axios'
import UploadQueue from './UploadQueue'
import MultipleInlineFileUploadProgressContainer from '../../components/MultipleFileUploadProgressContainer'
import { formatBytes } from '../../utils/formatting'
import { FileObjectToolbar } from './FileObjectToolbar'

interface FilesProps {
    updateCurrentPageDetails: (pageDetails: PageDetails) => void
    billableUnits: Array<UserBillableUnitInfo>
    currentUser: AuthenticatedUser | undefined
    currentBillableUnit: UserBillableUnitInfo | undefined
    currentProject: Project | undefined
    openConfirmationDialog: (confirmDialogProperties: ConfirmDialogProperties) => void
}

export type UploadInProgress = {
    rate: number | undefined
    fileName: string
    file: File
    percent: number
    error: string | undefined
    abortController: AbortController
}

enum LayoutType {
    LIST = 'LIST',
    CARDS = 'CARDS',
}

const PAGE_TITLE = 'Storage'

export default function Files(props: FilesProps) {
    const dropzone = useRef<DropzoneRef | null>(null)
    const [searchParams, setSearchParams] = useSearchParams()
    const [componentState, setComponentState] = useState(ComponentState.LOADING)
    const [currentLayoutType, setLayoutType] = useState<LayoutType>(LayoutType.LIST)
    const [fileObjectsContainer, setFileObjectsContainer] = useState<FileObjectsContainer | undefined>(undefined)
    const ICON_SIZE = currentLayoutType === LayoutType.LIST ? 16 : 22
    const [showFilePreviewModal, setShowFilePreviewModal] = useState(false)
    const [filePreviewModalText, setFilePreviewModalText] = useState('')
    const [filePreviewModalTitle, setFilePreviewModalTitle] = useState('')
    const [showCreateFolderModal, setShowCreateFolderModal] = useState(false)
    const [importProgressMsg, setImportProgressMsg] = useState<string | undefined>(undefined)
    const [showBanner, setShowBanner] = useState<boolean>(false)
    const currentPath = useRef('/')
    const [uploads, setUploads] = useState<Array<UploadInProgress>>([])
    const uploadQueue = new UploadQueue(3)
    const [isHoveringDropzone, setIsHoveringDropzone] = useState(false)
    const [allSelected, setAllSelected] = useState(false)
    const [selectedFileObjects, setSelectedFileObjects] = useState(new Set<FileObject>())

    useEffect(() => {
        const initializePage = async (project: Project) => {
            const pathFromQuery = searchParams.get(CURRENT_PATH_PARAM)
            if (pathFromQuery !== null) {
                console.debug(
                    `Found a path in query params, will use that as starting directory. Path is=${pathFromQuery}`
                )
                currentPath.current = pathFromQuery
                listFiles(project, pathFromQuery)
            } else {
                console.debug(`No path in query, will fetch root directory`)
                listFiles(project, '/')
            }
        }

        if (props.currentProject !== undefined && searchParams !== undefined) {
            initializePage(props.currentProject!)
        }
    }, [props.currentProject, searchParams])

    const showFilePickerDialog = () => {
        if (dropzone.current) {
            dropzone.current.open()
        }
    }

    const listFiles = (project: Project, path?: string) => {
        setSelectedFileObjects(new Set())
        setAllSelected(false)
        const list = async () => {
            try {
                setComponentState(ComponentState.LOADING)
                const response = await CloudApi.listStorageFilesInPath(project, path)

                setFileObjectsContainer(response.data)
                setComponentState(ComponentState.DONE)
            } catch (e: any) {
                if (e.response.status === 404) {
                    // When the last file in a directory is removed, the directory does no longer exist.
                    // For now lets go to root when this happens.
                    if (path !== '/') {
                        listFiles(project, '/')
                    } else {
                        console.log('404 at / - This probably means that there are no files uploaded')
                        setFileObjectsContainer(undefined)
                        setComponentState(ComponentState.DONE)
                    }
                } else {
                    console.error(e)
                    toast.error(
                        formattedToastMessage(
                            'File error',
                            'Failed to list files in current directory, please try again later.'
                        )
                    )
                    setComponentState(ComponentState.DONE)
                }
            }
        }
        list()
    }

    const createFolderInStorage = async (folderName: string) => {
        try {
            setComponentState(ComponentState.LOADING)
            await CloudApi.storageCreateFolder(props.currentProject!.uid, currentPath.current, folderName)
            listFiles(props.currentProject!, currentPath.current)
        } catch (err: any) {
            if (err.response && err.response.status === 409) {
                toast.error('File already exist')
            } else {
                toast.error('Unexpected error')
            }
            setComponentState(ComponentState.DONE)
        }
    }

    const updateUploadsInProgress = (newValue: UploadInProgress) => {
        setUploads((prevItems) =>
            prevItems.map((item) => (item.fileName === newValue.fileName ? { ...item, ...newValue } : item))
        )
    }

    const updateSelectedFileOjbects = (newValue: FileObject) => {
        //setSelectedFileObjects((prevItems) =>
        //    prevItems.map((item) => (item.path === newValue.path ? { ...item, ...newValue } : item))
        //)
    }

    const uploadFilesAsync = async (files: Array<File>) => {
        setIsHoveringDropzone(false)
        const newUploads = files.map((fileItem) => {
            const upload: UploadInProgress = {
                fileName: fileItem.name,
                file: fileItem,
                percent: 0,
                rate: undefined,
                error: undefined,
                abortController: new AbortController(),
            }
            return upload
        })
        setUploads((prev) => [...prev, ...newUploads])
        newUploads.forEach((u) => {
            uploadQueue.enqueue(() => uploadSingleFileAsync(u))
        })
    }

    const uploadSingleFileAsync = async (uploadInProgress: UploadInProgress) => {
        const pathWhenStarting = currentPath.current
        updateUploadsInProgress(uploadInProgress)
        const config = {
            onUploadProgress: function (progressEvent: AxiosProgressEvent) {
                uploadInProgress.percent = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 1))
                uploadInProgress.rate = progressEvent.rate
                updateUploadsInProgress(uploadInProgress)
            },
            signal: uploadInProgress.abortController.signal,
        } as AxiosRequestConfig<EventTarget>
        try {
            await CloudApi.storageUpload(props.currentProject!.uid, pathWhenStarting, uploadInProgress.file, config)

            if (pathWhenStarting === currentPath.current) {
                listFiles(props.currentProject!, currentPath.current)
            }
        } catch (err: any) {
            if (err.response && err.response.status === 409) {
                toast.error(`File ${uploadInProgress.fileName} already exist`)
                uploadInProgress.error = `File ${uploadInProgress.fileName} already exist`
                setComponentState(ComponentState.DONE)
            } else {
                uploadInProgress.error = `Unexpected Error`
            }
        }
        setUploads((prev) => prev.filter((item) => item.fileName !== uploadInProgress.fileName))
    }

    const deleteSelectedFiles = () => {
        props.openConfirmationDialog({
            dialogTitle: 'Are you sure?',
            bodyText: (
                <>
                    Are you sure you want to <b>delete</b> the <b>{selectedFileObjects.size} selected</b> files &
                    directories and any files within those directories?
                </>
            ),
            bodySubtitle: 'You can not undo this action.',
            confirmButtonText: 'Yes, delete',
            cancelButtonText: 'No, cancel',
            handleCancelFunction: () => console.log,
            handleConfirmFunction: () => doDeleteSelectedFiles(),
        } as ConfirmDialogProperties)
    }

    const doDeleteSelectedFiles = () => {
        const pathWhenStarting = currentPath.current
        const deleteIt = async () => {
            setComponentState(ComponentState.LOADING)
            try {
                for (const fileObject of Array.from(selectedFileObjects)) {
                    const path =
                        fileObject.type === FileObjectTypeEnum.DIRECTORY ? `${fileObject.path}` : fileObject.path
                    const deleteResponse = await CloudApi.deleteStorageFile(props.currentProject!, path)
                }
                if (pathWhenStarting === currentPath.current) {
                    listFiles(props.currentProject!, fileObjectsContainer?.path)
                }
            } catch (e: any) {
                console.error(e)
                toast.error(
                    formattedToastMessage('File error', 'Failed to delete selected file, please try again later.')
                )
            }
            setComponentState(ComponentState.DONE)
        }

        deleteIt()
    }

    const deleteFile = (fileObject: FileObject, project: Project) => {
        const deleteIt = async () => {
            setComponentState(ComponentState.LOADING)
            try {
                const path = fileObject.type === FileObjectTypeEnum.DIRECTORY ? `${fileObject.path}` : fileObject.path
                const deleteResponse = await CloudApi.deleteStorageFile(project, path)
                if (deleteResponse.status < 300) {
                    toast.success(`Successfully deleted ${fileObject.name}!`)
                    listFiles(project, fileObjectsContainer?.path)
                }
            } catch (e: any) {
                console.error(e)
                toast.error(
                    formattedToastMessage('File error', 'Failed to delete selected file, please try again later.')
                )
            }
            setComponentState(ComponentState.DONE)
        }

        deleteIt()
    }

    const showPreviewModal = (fileObject: FileObject, project: Project) => {
        const downloadAndShow = async () => {
            try {
                setComponentState(ComponentState.LOADING)
                const downloadUrl = await CloudApi.getStorageFileUrl(project, `${fileObject.path}`)

                const response = await CloudApi.downloadPreviewContentsFromSignedUrl(downloadUrl.data)

                setFilePreviewModalText(response.data)
                setShowFilePreviewModal(true)
                setFilePreviewModalTitle(fileObject.name)
                setComponentState(ComponentState.DONE)
            } catch (e: any) {
                console.error(e)
                toast.error(
                    formattedToastMessage('File error', 'Failed to download selected file, please try again later.')
                )
            }
            setComponentState(ComponentState.DONE)
        }

        downloadAndShow()
    }

    const importFileAsRecording = async (fileObject: FileObject) => {
        setShowBanner(true)
        setImportProgressMsg('Copying file...')
        try {
            await CloudApi.importStorageFileAsRecording(props.currentProject!!, fileObject.path)
            setImportProgressMsg(undefined)
        } catch (e: any) {
            setImportProgressMsg('Failed to copy file')
        }
    }

    const downloadFile = (fileObject: FileObject, project: Project) => {
        const download = async () => {
            try {
                setComponentState(ComponentState.LOADING)
                const downloadUrl = await CloudApi.getStorageFileUrl(project, `${fileObject.path}`)
                const fileContents = await fetch(downloadUrl.data)
                const blob = await fileContents.blob()
                // Download file by creating a link, faking a click on it, and then removing the link again
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = fileObject.name
                link.click()
                link.parentNode?.removeChild(link)
            } catch (e: any) {
                console.error(e)
                toast.error(
                    formattedToastMessage('File error', 'Failed to download selected file, please try again later.')
                )
            }
            setComponentState(ComponentState.DONE)
        }

        download()
    }

    const clickDirectory = (path: string) => {
        searchParams.set(CURRENT_PATH_PARAM, path)
        setSearchParams(searchParams)
        currentPath.current = path
    }

    const getFileSuffix = (filename: string): string => {
        const parts = filename.split('.')
        return parts.length > 1 ? parts.pop() || '' : ''
    }

    const isImportRecordingPossible = (title: string) => {
        const suffix = getFileSuffix(title)
        return ALLOWED_RECORDING_FILE_NAME_ENDINGS.includes(suffix)
    }

    const isCurrentPathFile = () => {
        return fileObjectsContainer !== undefined && fileObjectsContainer.type === FileObjectTypeEnum.FILE
    }

    const pageHeader = () => {
        return (
            <>
                <div className="text-start d-flex justify-content-between align-items-center text-truncate">
                    <div className="text-truncate d-flex align-items-center">
                        <StorageIcon className="remotive-primary-50-color fs-3 me-1" />
                        <p className="fs-3 lexend-light text-truncate m-0">{PAGE_TITLE}</p>
                    </div>

                    <div>
                        {!isCurrentPathFile() && (
                            /*Do not show upload or create folder when on a file node */
                            <>
                                <button
                                    className="btn rounded-circle p-0 px-1 m-0 remotive-btn-primary me-1"
                                    disabled={
                                        !hasPermission(
                                            Permission.PROJECT_EDITOR_CONFIG,
                                            props.currentBillableUnit,
                                            props.currentProject
                                        )
                                    }
                                    title={'Create folder'}
                                    onClick={() => setShowCreateFolderModal(true)}
                                >
                                    <CreateFolderIcon
                                        style={{ marginBottom: '3px', padding: '1px' }}
                                        sx={{ fontSize: 18 }}
                                    />
                                </button>
                                <button
                                    className="btn rounded-circle p-0 px-1 m-0 remotive-btn-primary me-1"
                                    disabled={
                                        !hasPermission(
                                            Permission.PROJECT_EDITOR_CONFIG,
                                            props.currentBillableUnit,
                                            props.currentProject
                                        )
                                    }
                                    title={'Upload files'}
                                    onClick={showFilePickerDialog}
                                >
                                    <UploadIcon style={{ marginBottom: '3px', padding: '1px' }} sx={{ fontSize: 18 }} />
                                </button>
                            </>
                        )}
                        {props.currentProject !== undefined && fileObjectsContainer !== undefined && (
                            <button
                                className="btn rounded-circle p-0 px-1 m-0 remotive-btn-primary"
                                disabled={
                                    !hasPermission(
                                        Permission.PROJECT_VIEWER_CONFIG,
                                        props.currentBillableUnit,
                                        props.currentProject
                                    )
                                }
                                title={'Reload'}
                                onClick={() => {
                                    listFiles(props.currentProject!, fileObjectsContainer.path)
                                }}
                            >
                                <RefreshIcon style={{ marginBottom: '3px', padding: '1px' }} sx={{ fontSize: 18 }} />
                            </button>
                        )}
                    </div>
                </div>
            </>
        )
    }

    const noFilesInPathWarning = () => {
        return (
            <>
                <NotFoundContaner infoText="We couldn't find any files..." secondaryText="Upload some files first" />
            </>
        )
    }

    const renderFileObjectPath = (fileObjectsContainer: FileObjectsContainer) => {
        const isCurrentFolderOrFile = (index: number, sourceArray: string[]) => {
            return index === sourceArray.length - 1
        }

        if (!props.currentProject || fileObjectsContainer.path === undefined) {
            return <></>
        }
        const path = ['/', ...fileObjectsContainer.path.split('/').slice(1)]
        const includeFilename = fileObjectsContainer.type === FileObjectTypeEnum.FILE
        return (
            <div className="d-flex justify-content-start mt-2 mx-3">
                <div className="remotive-font-md m-0 d-flex">
                    {path.slice(0, path.length - (includeFilename ? 0 : 1)).map((it, index, sourceArray) => (
                        <div key={it} className="me-1">
                            <p className="m-0">
                                {isCurrentFolderOrFile(index, sourceArray) ? (
                                    /* No link on current folder or file in breadcrumb */
                                    <span className={'lexend-bold'}>{it}</span>
                                ) : (
                                    <span
                                        className={'clickable-underlined-on-hover text-secondary'}
                                        onClick={() =>
                                            clickDirectory(
                                                sourceArray
                                                    .slice(0, index + 1)
                                                    .join('/')
                                                    .substring(1) + '/'
                                            )
                                        }
                                    >
                                        {it}
                                    </span>
                                )}
                                {' >'}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    const renderFileObjectList = (fileObjectsContainer: FileObjectsContainer) => {
        if (fileObjectsContainer.objects && fileObjectsContainer.objects.length <= 0) {
            return <></>
        }

        return (
            <div
                style={{
                    display: currentLayoutType === LayoutType.CARDS ? 'grid' : undefined,
                    gridTemplateColumns:
                        currentLayoutType === LayoutType.CARDS ? 'repeat(auto-fill, minmax(500px, 1fr))' : undefined,
                }}
            >
                {fileObjectsContainer.objects &&
                    fileObjectsContainer.objects
                        .sort((objectA, objectB) => objectA.type.localeCompare(objectB.type))
                        .map((it) => (
                            <div key={it.path} style={{ display: 'flex', flexDirection: 'column' }}>
                                {it.type === FileObjectTypeEnum.DIRECTORY
                                    ? directoryFileCard(it)
                                    : it.type === FileObjectTypeEnum.FILE
                                    ? fileCard(it)
                                    : unknownFileCard(it)}
                            </div>
                        ))}

                {!fileObjectsContainer.objects && (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>{fileCard(fileObjectsContainer)}</div>
                )}
            </div>
        )
    }

    const getIconBasedOnFileName = (fileName: string) => {
        if (COMMON_IMAGE_FILE_TYPES.some((it) => fileName.endsWith(it))) {
            return <ImageFileIcon sx={{ fontSize: ICON_SIZE }} className="remotive-primary-60-color" />
        }
        if (COMMON_TEXT_FILE_TYPES.some((it) => fileName.endsWith(it))) {
            return <TextFileIcon sx={{ fontSize: ICON_SIZE }} className="remotive-primary-60-color" />
        }
        if (COMMON_VIDEO_FILE_TYPES.some((it) => fileName.endsWith(it))) {
            return <VideoFileIcon sx={{ fontSize: ICON_SIZE }} className="remotive-primary-60-color" />
        }
        if (COMMON_SOUND_FILE_TYPES.some((it) => fileName.endsWith(it))) {
            return <SoundFileIcon sx={{ fontSize: ICON_SIZE }} className="remotive-primary-60-color" />
        }
        if (COMMON_COMPRESSED_FILE_TYPES.some((it) => fileName.endsWith(it))) {
            return <ZippedFileIcon sx={{ fontSize: ICON_SIZE }} className="remotive-primary-60-color" />
        }
        return <GenericFileIcon sx={{ fontSize: ICON_SIZE }} className="remotive-primary-60-color" />
    }

    const shareLink = (fileObject: FileObject) => {
        try {
            navigator.clipboard.writeText(
                `${window.location.origin}/p/${props.currentProject!.uid}/files?path=${encodeURI(fileObject.path)}`
            )
            toast.success('Link copied to clipboard')
        } catch (e: any) {
            toast.error('Failed to copy link to clipboard')
        }
    }

    const fileCard = (fileObject: FileObject) => {
        if (props.currentProject !== undefined) {
            return fileObjectCard(
                getIconBasedOnFileName(fileObject.name),
                fileObject.name,
                formatBytes(fileObject.size ?? 0),
                fileObject.uploaded,
                'remotive-primary-20-background',
                'remotive-success-100-color',
                false,
                () => clickDirectory(fileObject.path),
                () => showPreviewModal(fileObject, props.currentProject!),
                () => downloadFile(fileObject, props.currentProject!),
                () =>
                    props.openConfirmationDialog({
                        dialogTitle: 'Are you sure?',
                        bodyText: (
                            <>
                                Are you sure you want to delete the file <b>{fileObject.name}?</b>
                            </>
                        ),
                        bodySubtitle: 'You can not undo this action.',
                        confirmButtonText: 'Yes, delete it',
                        cancelButtonText: 'No, cancel',
                        handleCancelFunction: () => console.log,
                        handleConfirmFunction: () => deleteFile(fileObject, props.currentProject!),
                    } as ConfirmDialogProperties),
                () => importFileAsRecording(fileObject),
                () => shareLink(fileObject),
                fileObject
            )
        }
        return <></>
    }

    const unknownFileCard = (fileObject: FileObject) => {
        return fileObjectCard(
            <WarningRounded sx={{ fontSize: ICON_SIZE }} className="text-danger" />,
            fileObject.name,
            'Unknown file',
            undefined,
            'bg-danger-subtle',
            'text-danger',
            true,
            () =>
                toast.error(
                    formattedToastMessage(
                        'File error',
                        'This file is unknown, please reach out to support@remotivelabs.com.'
                    )
                ),
            () =>
                toast.error(
                    formattedToastMessage(
                        'File error',
                        'This file is unknown, please reach out to support@remotivelabs.com.'
                    )
                ),
            () =>
                toast.error(
                    formattedToastMessage(
                        'File error',
                        'This file is unknown, please reach out to support@remotivelabs.com.'
                    )
                ),
            () =>
                toast.error(
                    formattedToastMessage(
                        'File error',
                        'This file is unknown, please reach out to support@remotivelabs.com.'
                    )
                ),
            () =>
                toast.error(
                    formattedToastMessage(
                        'File error',
                        'This file is unknown, please reach out to support@remotivelabs.com.'
                    )
                ),
            () =>
                toast.error(
                    formattedToastMessage(
                        'File error',
                        'This file is unknown, please reach out to support@remotivelabs.com.'
                    )
                ),
            fileObject
        )
    }

    const directoryFileCard = (fileObject: FileObject) => {
        if (props.currentProject !== undefined) {
            return fileObjectCard(
                <DirectoryIcon sx={{ fontSize: ICON_SIZE }} className="remotive-success-100-color" />,
                fileObject.name,
                'Directory',
                undefined,
                'remotive-success-10-background',
                'remotive-success-100-color',
                true,
                () => clickDirectory(fileObject.path),
                () => toast.error(formattedToastMessage('File error', 'It is not possible to download a directory.')),
                () => toast.error(formattedToastMessage('File error', 'It is not possible to preview a directory.')),
                () =>
                    props.openConfirmationDialog({
                        dialogTitle: 'Are you sure?',
                        bodyText: (
                            <>
                                Are you sure you want to delete the directory{' '}
                                <b>{fileObject.name} and all files in it?</b>
                            </>
                        ),
                        bodySubtitle: 'You can not undo this action.',
                        confirmButtonText: 'Yes, delete it',
                        cancelButtonText: 'No, cancel',
                        handleCancelFunction: () => console.log,
                        handleConfirmFunction: () => deleteFile(fileObject, props.currentProject!),
                    } as ConfirmDialogProperties),
                () => toast.error(formattedToastMessage('File error', 'It is not possible to import a directory.')),
                () => shareLink(fileObject),
                fileObject
            )
        }
        return <></>
    }

    const markAllAsSelected = (toSelected: boolean) => {
        if (toSelected) {
            if (fileObjectsContainer?.objects && fileObjectsContainer.objects.length > 0) {
                setSelectedFileObjects(new Set(fileObjectsContainer.objects))
            }
        } else {
            setSelectedFileObjects(new Set())
        }
    }

    const selectedFileObjectSelectionChanged = (fileObject: FileObject) => {
        const isSelected = Array.from(selectedFileObjects).filter((f) => f.path === fileObject.path).length > 0
        if (!isSelected) {
            setSelectedFileObjects((prev) => {
                const newSet = new Set(prev) // Create a new Set from previous state
                newSet.add(fileObject) // Add the new item
                return newSet // Update state with the new Set
            })
        } else {
            setSelectedFileObjects((prev) => {
                const newSet = new Set(prev) // Create a new Set from previous state
                newSet.delete(fileObject) // Add the new item
                return newSet // Update state with the new Set
            })
        }
    }

    const fileObjectCard = (
        icon: ReactElement,
        title: string,
        subtitle: string,
        uploaded: string | undefined,
        cardClassName: string,
        moreButtonClassName: string,
        isDirectory: boolean,
        onClickFunction: () => void,
        onPreviewFunction: () => void,
        onDownloadFunction: () => void,
        onDeleteFunction: () => void,
        onImportFunction: () => void,
        onCopyLinkFunction: () => void,
        fileObject: FileObject
    ) => {
        return (
            <div
                className={`rounded-3 ${
                    currentLayoutType === LayoutType.LIST ? 'flex-grow-1' : 'p-1 m-1'
                } ${cardClassName}`}
                style={{ marginBottom: 3 }}
            >
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <div
                        className={`d-flex flex-row align-items-center flex-truncate ${
                            currentLayoutType === LayoutType.LIST ? 'flex-grow-1 py-1' : ''
                        }`}
                    >
                        {/* Select All Checkbox */}
                        <div className="ms-2 me-3  d-flex align-items-center">
                            <Form.Check
                                type="checkbox"
                                checked={
                                    Array.from(selectedFileObjects).filter((f) => f.path === fileObject.path).length > 0
                                }
                                onChange={() => {
                                    selectedFileObjectSelectionChanged(fileObject)
                                }}
                            />
                        </div>
                        <div className="ms-2 me-3  d-flex align-items-center">{icon}</div>
                        <div
                            title={title}
                            className={`d-flex ${
                                currentLayoutType === LayoutType.LIST
                                    ? 'flex-row flex-grow-1 align-items-end'
                                    : 'flex-column'
                            } flex-truncate clickable-underlined-on-hover `}
                            onClick={onClickFunction}
                        >
                            <p
                                className={`text-truncate text-start m-0 me-2 ${
                                    currentLayoutType === LayoutType.LIST ? 'remotive-font-sm' : 'remotive-font-md'
                                }`}
                            >
                                {title}
                            </p>
                            <p
                                style={{
                                    marginBottom: currentLayoutType === LayoutType.LIST ? 1 : 0,
                                    marginRight: currentLayoutType === LayoutType.LIST ? 5 : 0,
                                }}
                                className="text-truncate text-start text-secondary remotive-font-xs"
                            >
                                {subtitle}
                            </p>
                            <p
                                style={{ marginBottom: currentLayoutType === LayoutType.LIST ? 1 : 0 }}
                                className="text-truncate text-start text-secondary remotive-font-xs"
                            >
                                {uploaded !== undefined && uploaded.length > 0 ? (
                                    `${new Date(uploaded).toLocaleString()}`
                                ) : (
                                    <wbr />
                                )}
                            </p>
                        </div>
                    </div>
                    <div>
                        <div className="pe-2 py-0 d-flex align-items-center">
                            {!isDirectory && (
                                <Dropdown>
                                    <Dropdown.Toggle className={`p-0 bg-transparent border-0 ${moreButtonClassName}`}>
                                        <MoreVerticalIcon sx={{ fontSize: ICON_SIZE }} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu
                                        style={{ minWidth: 120 }}
                                        className="remotive-font-md remotive-dropdown-dark text-light"
                                    >
                                        <Dropdown.Item className="px-2" onClick={onDownloadFunction}>
                                            <div className="d-flex flex-row align-items-center justify-content-between">
                                                <p className="m-0 remotive-font-sm">Download</p>
                                                <DownloadIcon sx={{ fontSize: 15 }} className="me-2" />
                                            </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item className="px-2" onClick={onPreviewFunction}>
                                            <div className="d-flex flex-row align-items-center justify-content-between">
                                                <p className="m-0 remotive-font-sm">Preview</p>
                                                <ViewIcon sx={{ fontSize: 15 }} className="me-2" />
                                            </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item className="px-2" onClick={onDeleteFunction}>
                                            <div className="d-flex flex-row align-items-center justify-content-between">
                                                <p className="m-0 remotive-font-sm">Delete</p>
                                                <DeleteIcon sx={{ fontSize: 15 }} className="me-2" />
                                            </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item className="px-2" onClick={onCopyLinkFunction}>
                                            <div className="d-flex flex-row align-items-center justify-content-between">
                                                <p className="m-0 remotive-font-sm">Share link</p>
                                                <ShareIcon sx={{ fontSize: 15 }} className="me-2" />
                                            </div>
                                        </Dropdown.Item>
                                        {isImportRecordingPossible(title) && (
                                            <Dropdown.Item className="px-2" onClick={onImportFunction}>
                                                <div className="d-flex flex-row align-items-center justify-content-between">
                                                    <p className="m-0 remotive-font-sm">
                                                        Import as recording&nbsp;&nbsp;&nbsp;
                                                    </p>
                                                    <ImportExportIcon sx={{ fontSize: 15 }} className="me-2" />
                                                </div>
                                            </Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}
                            {isDirectory && (
                                <Dropdown>
                                    <Dropdown.Toggle className={`p-0 bg-transparent border-0 ${moreButtonClassName}`}>
                                        <MoreVerticalIcon sx={{ fontSize: ICON_SIZE }} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu
                                        style={{ minWidth: 120 }}
                                        className="remotive-font-md remotive-dropdown-dark text-light"
                                    >
                                        <Dropdown.Item className="px-2" onClick={onDeleteFunction}>
                                            <div className="d-flex flex-row align-items-center justify-content-between">
                                                <p className="m-0 remotive-font-sm">Delete</p>
                                                <DeleteIcon sx={{ fontSize: 15 }} className="me-2" />
                                            </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item className="px-2" onClick={onCopyLinkFunction}>
                                            <div className="d-flex flex-row align-items-center justify-content-between">
                                                <p className="m-0 remotive-font-sm">Share link</p>
                                                <ShareIcon sx={{ fontSize: 15 }} className="me-2" />
                                            </div>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const outerFileObjectsContainer = (fileObjectsContainer: FileObjectsContainer) => {
        return (
            <>
                <div className="d-flex flex-row justify-content-between">
                    <div>{renderFileObjectPath(fileObjectsContainer)}</div>
                    <div className="me-2 ">
                        <button
                            onClick={() => setLayoutType(LayoutType.LIST)}
                            className={`btn remotive-btn-tab ${
                                currentLayoutType === LayoutType.LIST ? 'text-dark' : ''
                            } p-0`}
                        >
                            <ListViewIcon sx={{ fontSize: 20 }} />
                        </button>
                        <button
                            onClick={() => setLayoutType(LayoutType.CARDS)}
                            className={`btn remotive-btn-tab ${
                                currentLayoutType === LayoutType.CARDS ? 'text-dark' : ''
                            } p-1`}
                        >
                            <GridViewIcon sx={{ fontSize: 20 }} />
                        </button>
                    </div>
                </div>
                <div
                    className={`text-start rounded-3 ${
                        currentLayoutType === LayoutType.LIST ? 'flex-grow-1 ps-2 ' : 'ps-3 m-1'
                    }`}
                    style={{ marginBottom: 3 }}
                >
                    {' '}
                    <div className="d-flex flex-row align-items-center">
                        {fileObjectsContainer.objects && fileObjectsContainer.objects.length > 0 && (
                            <>
                                <FileObjectToolbar
                                    onSelectAll={() => markAllAsSelected(!allSelected)}
                                    onDeleteSelected={() => deleteSelectedFiles()}
                                    onDownloadSelected={() => console.log('Download')}
                                    onReset={() => markAllAsSelected(false)}
                                    selectedFiles={selectedFileObjects}
                                />
                            </>
                        )}
                    </div>
                </div>
                <div className={`${currentLayoutType === LayoutType.LIST ? 'col-12 px-2' : ' mx-2'}  mt-1 mb-2`}>
                    {renderFileObjectList(fileObjectsContainer)}
                </div>
            </>
        )
    }

    const getBody = () => {
        switch (componentState) {
            case ComponentState.LOADING:
                return (
                    <>
                        <div className="d-flex flex-row justify-content-between">
                            <div></div>
                            <div className="me-2 ">
                                <button
                                    onClick={() => setLayoutType(LayoutType.LIST)}
                                    className={`btn remotive-btn-tab ${
                                        currentLayoutType === LayoutType.LIST ? 'text-dark' : ''
                                    } p-0`}
                                >
                                    <ListViewIcon sx={{ fontSize: 20 }} />
                                </button>
                                <button
                                    onClick={() => setLayoutType(LayoutType.CARDS)}
                                    className={`btn remotive-btn-tab ${
                                        currentLayoutType === LayoutType.CARDS ? 'text-dark' : ''
                                    } p-1`}
                                >
                                    <GridViewIcon sx={{ fontSize: 20 }} />
                                </button>
                            </div>
                        </div>
                        <div className="d-flex flex-column flex-grow-1 justify-content-between">
                            <div className="d-flex justify-content-center align-items-center mx-2 mt-1">
                                <LoadingContainer spinnerSize="lg" loadingText=" " />
                            </div>
                            <div className={'w-100'}>
                                {!isCurrentPathFile() && <div className={'m-2'}>{storageDropZone()}</div>}
                            </div>
                        </div>
                    </>
                )

            case ComponentState.ERROR:
                return (
                    <>
                        <ErrorContainer />
                    </>
                )

            case ComponentState.DONE:
            case ComponentState.DEFAULT:
                return (
                    <div className={'d-flex flex-column flex-grow-1 justify-content-between h-100'}>
                        <div>
                            {fileObjectsContainer !== undefined
                                ? outerFileObjectsContainer(fileObjectsContainer)
                                : noFilesInPathWarning()}
                        </div>
                        {!isCurrentPathFile() && <div className={'m-2'}>{storageDropZone()}</div>}
                    </div>
                )
            default:
                return <></>
        }
    }

    const breadCrumbs = () => {
        return {
            trail: [
                {
                    title: 'Home /',
                    route: OrganisationRoutes.toHome(props.currentBillableUnit?.organisation.uid),
                } as BreadCrumb,
            ],
            current: {
                title: PAGE_TITLE,
                route: undefined,
            } as BreadCrumb,
        } as BreadCrumbs
    }

    const cliHints = () => {
        const hints: Array<CliHint> = []
        hints.push({
            title: `Copy file to storage root directory`,
            command: `remotive cloud storage cp myfile.txt rcs:/${currentPath.current}  --project ${props.currentProject?.uid}`,
        })

        hints.push({
            title: `Copy file to remote storage but change filename`,
            command: `remotive cloud storage cp myfile.txt rcs:/${currentPath.current}myfile2.txt  --project ${props.currentProject?.uid}`,
        })

        hints.push({
            title: `Copy file from remote storage`,
            command: `remotive cloud storage cp rcs:/${currentPath.current}myfile.txt .  --project ${props.currentProject?.uid}`,
        })

        hints.push({
            title: `Copy file from remote storage but change filename`,
            command: `remotive cloud storage cp rcs:/${currentPath.current}myfile.txt myfile2.txt  --project ${props.currentProject?.uid}`,
        })

        hints.push({
            title: `Delete a remote file`,
            command: `remotive cloud storage rm rcs:/${currentPath.current}myfile.txt --project ${props.currentProject?.uid}`,
        })

        hints.push({
            title: `List files in a directory`,
            command: `remotive cloud storage ls rcs:/${currentPath.current} --project ${props.currentProject?.uid}`,
        })

        return hints
    }

    const closeModal = () => {
        setShowFilePreviewModal(false)
        setFilePreviewModalText('')
    }
    //const HIGHLIGHT_HELP_OBJECT_STYLE = showHelpModal ? { zIndex: 9999, position: 'relative' as 'relative' } : {}
    const storageDropZone = () => {
        if (hasPermission(Permission.PROJECT_EDITOR_CONFIG, props.currentBillableUnit, props.currentProject)) {
            return (
                <>
                    <div className="d-flex" style={{ height: '47px !important' /*...HIGHLIGHT_HELP_OBJECT_STYLE*/ }}>
                        <Dropzone
                            ref={dropzone}
                            //disabled={uploads.length > 0}
                            multiple={true}
                            onDrop={(acceptedFiles) => uploadFilesAsync(acceptedFiles)}
                            onDragEnter={() => {
                                setIsHoveringDropzone(true)
                            }}
                            onDragLeave={() => {
                                setIsHoveringDropzone(false)
                            }}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div
                                    className={`dropzone rounded-2 ${
                                        isHoveringDropzone
                                            ? 'remotive-primary-10-background'
                                            : 'remotive-primary-0-background'
                                    }`}
                                    style={{
                                        height: '94px',
                                    }}
                                    {...getRootProps()}
                                >
                                    <input {...getInputProps()} />
                                    <p className="m-0 remotive-font-md remotive-primary-70-color text-center">
                                        <CloudUploadIcon className="me-2" sx={{ fontSize: 35 }} /> Drag a file here or
                                        click to <b>upload</b>
                                    </p>
                                </div>
                            )}
                        </Dropzone>
                    </div>
                    {uploads.length > 0 && (
                        <div style={{ background: 'white' }} className={'m-2 p-2'}>
                            <>
                                {uploads.map((u) => (
                                    <div key={u.fileName} className={'w-100'}>
                                        <MultipleInlineFileUploadProgressContainer
                                            inProgressText={
                                                u.percent === 0 ? `Queued ${u.fileName}` : `Uploading ${u.fileName}`
                                            }
                                            finishedText="Upload complete, finishing up..."
                                            uploadInProgress={u}
                                        />
                                    </div>
                                ))}
                            </>
                        </div>
                    )}
                </>
            )
        }
        return <></>
    }

    return (
        <div className="d-flex">
            <NavigationBar
                billableUnits={props.billableUnits}
                currentUser={props.currentUser}
                currentBillableUnit={props.currentBillableUnit}
                projects={props.currentBillableUnit?.projects || []}
                currentProject={props.currentProject}
                breadCrumbs={breadCrumbs()}
                isDemoGuideActive={false}
                openConfirmationDialog={props.openConfirmationDialog}
            />
            <Container fluid className="mt-5 pb-5 d-flex flex-column">
                <div className="mt-3">{pageHeader()}</div>

                <Alert
                    show={showBanner}
                    onClose={() => setShowBanner(false)}
                    dismissible={true}
                    className="border-0 d-flex p-0 flex-column rounded remotive-primary-20-background my-3"
                >
                    <div className="p-3 px-4">
                        <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                            <p style={{ zIndex: 10 }} className="m-0 remotive-font-sm remotive-primary-80-color">
                                {importProgressMsg && <>{importProgressMsg}</>}
                                {!importProgressMsg && (
                                    <>
                                        Import started, track progress under
                                        <Link to={`/p/${props.currentProject?.uid}/recordings`}> recordings</Link>
                                    </>
                                )}
                            </p>
                        </div>
                    </div>
                </Alert>
                <div className="mt-0">
                    <RLCard body={getBody()} />
                </div>
                <CliHintContainer
                    topMargin={'mt-5'}
                    productAnalyticsProperties={
                        {
                            productAnalyticsContext: ProductAnalyticsContext.STORAGE,
                            currentBillableUnit: props.currentBillableUnit,
                            currentUser: props.currentUser,
                        } as ProductAnalyticsProperties
                    }
                    hints={cliHints()}
                />
            </Container>
            <FilePreviewModal
                show={showFilePreviewModal}
                text={filePreviewModalText}
                title={filePreviewModalTitle}
                handleCloseFunction={() => closeModal()}
            />
            <CreateFolderModal
                show={showCreateFolderModal}
                text={''}
                title={''}
                handleCloseFunction={() => setShowCreateFolderModal(false)}
                createFolderFunction={createFolderInStorage}
            />
        </div>
    )
}
