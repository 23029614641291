import { ProgressBar, Spinner } from 'react-bootstrap'
import { CancelRounded, SuccessIcon } from '../assets/Icons'
import { formatBytes } from '../utils/formatting'
import { UploadInProgress } from '../pages/Storage'

interface MultipleInlineFileUploadProgressContainerProps {
    inProgressText: string
    finishedText: string
    uploadInProgress: UploadInProgress
}

export default function MultipleInlineFileUploadProgressContainer(
    props: MultipleInlineFileUploadProgressContainerProps
) {
    const fileUploadProgressContainer = () => {
        return (
            <div className="d-flex flex-column justify-content-center h-100 w-100 pb-2">
                {props.uploadInProgress.percent < 100 && (
                    <>
                        <div className="d-flex align-items-center text-start mb-2">
                            <Spinner size="sm" className={`remotive-primary-50-color`} />
                            <p className={`remotive-font-md m-0 ms-2`}>
                                {props.uploadInProgress.rate
                                    ? `${props.inProgressText} - ${formatBytes(props.uploadInProgress.rate)}/s`
                                    : props.inProgressText}
                            </p>
                            <button
                                className="btn rounded-circle p-0 px-1 m-0 me-1 remotive-warning-100-color"
                                title={'Cancel upload'}
                                onClick={() => {
                                    props.uploadInProgress.abortController.abort()
                                    props.uploadInProgress.percent = 100
                                }}
                            >
                                <CancelRounded style={{ marginBottom: '3px', padding: '1px' }} sx={{ fontSize: 18 }} />
                            </button>
                        </div>
                        <div className="d-flex text-start">
                            <ProgressBar
                                className={`col-12 remotive-primary-0-color`}
                                label={`${props.uploadInProgress.percent}%`}
                                now={props.uploadInProgress.percent}
                            />
                        </div>
                    </>
                )}
            </div>
        )
    }

    return fileUploadProgressContainer()
}
