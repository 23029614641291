import { useEffect, useState } from 'react'
import { Form, Modal, Spinner } from 'react-bootstrap'
import { FileWithPath } from 'react-dropzone'
import CloudApi from 'src/api/CloudApi'
import { BrokerConfigurationFolder, Project, RecordingSession } from 'src/api/CloudApi/types'
import TextBanner, { BannerType } from 'src/components/TextBanner'
import { UserRecordingSessionContext } from 'src/types/Context'
import {
    createAnalyticsTrackingKey,
    ProductAnalyticsProperties,
    ProductAnalyticsProps,
    useProductAnalyticsClient,
} from 'src/utils/ProductAnalytics'

interface FileUploadConfirmationModalProps {
    userRecordingSessionContext: UserRecordingSessionContext
    productAnalyticsProperties: ProductAnalyticsProperties
    show: boolean
    hasPermissionToUpload: boolean

    transformationFilesPreparedForUpload: Array<FileWithPath>

    transformationFilesToUpload: Array<FileWithPath> // <-- This contains files that should be checked or not
    setTransformationFilesToUpload: (files: Array<FileWithPath>) => void // <-- This sets files that should be checked or not

    setUploadPercent: (percent: number) => void

    deleteTransformationFolder: (folder: BrokerConfigurationFolder, skipToast: boolean) => void

    onClose: Function

    onUploadBegin?: Function
    onUploadComplete?: Function
    onUploadFailed?: Function
}

type FileNode = FileWithPath
type FolderNode = { [key: string]: FileNode | FolderNode }
type Node = FileNode | FolderNode

export function FileUploadConfirmationModal(props: FileUploadConfirmationModalProps) {
    const [isDeletingOldFiles, setIsDeletingOldFiles] = useState<boolean>(false)

    const productAnalyticsClient = useProductAnalyticsClient({
        user: props.userRecordingSessionContext.currentUser,
        billableUnit: props.userRecordingSessionContext.currentBillableUnit,
    } as ProductAnalyticsProps)

    const AnalyticsActions = {
        UPLOAD_CONFIGURATION: createAnalyticsTrackingKey(
            props.productAnalyticsProperties.productAnalyticsContext,
            'UploadConfiguration'
        ),
        UPLOAD_FAILED_CONFIGURATION: createAnalyticsTrackingKey(
            props.productAnalyticsProperties.productAnalyticsContext,
            'UploadConfigurationFailedUnknownError'
        ),
    }

    const saveBrokerConfigFiles = async (
        project: Project,
        recordingSession: RecordingSession,
        transformationFilesToUpload: Array<FileWithPath>
    ) => {
        try {
            if (props.onUploadBegin !== undefined) {
                props.onUploadBegin()
                props.setUploadPercent(0)
            }
            await CloudApi.uploadBrokerConfigToRecordingSession(
                project.uid,
                recordingSession.sessionId,
                'asdf',
                transformationFilesToUpload,
                props.setUploadPercent
            )
            if (props.onUploadComplete !== undefined) {
                props.onUploadComplete()
            }
        } catch (err: any) {
            console.error(err)
            if (props.onUploadFailed !== undefined) {
                props.onUploadFailed()
            }
        }
    }

    const getConfigurationIfAlreadyExists: () => BrokerConfigurationFolder | undefined = () => {
        // Path differs depending on if we use dropzone (starts with /) or pick a file with file picker (without /).
        // here we make sure to always compare without slash

        const brokerPath = props.transformationFilesPreparedForUpload![0]!.path!
        let pathName = ''
        if (brokerPath.startsWith('/')) {
            pathName = brokerPath.substring(brokerPath.indexOf('/') + 1)
        } else {
            pathName = brokerPath
        }
        const configs = props.userRecordingSessionContext.currentRecordingSession.brokerConfigurations.filter((c) => {
            return c.name === pathName.substring(0, pathName.indexOf('/'))
        })
        return configs.length > 0 ? configs[0] : undefined
    }

    const configurationAlreadyExists = () => {
        return getConfigurationIfAlreadyExists() !== undefined
    }

    const parseFileTreeIteratively = (files: Array<FileWithPath>): FolderNode => {
        const tree: FolderNode = {}

        files.forEach((file) => {
            const parts = file.path!.split('/').filter(Boolean)
            let currentLevel: FolderNode = tree

            parts.forEach((part, index) => {
                if (!currentLevel[part]) {
                    currentLevel[part] = index === parts.length - 1 ? file : {}
                }
                currentLevel = currentLevel[part] as FolderNode
            })
        })

        return tree
    }

    const handleFileOnChange = (file: FileWithPath, isChecked: boolean) => {
        const updatedFiles = isChecked
            ? [...props.transformationFilesToUpload, file]
            : props.transformationFilesToUpload.filter((f) => f.path !== file.path)
        props.setTransformationFilesToUpload(updatedFiles)
    }

    const handleFolderOnChange = (node: FolderNode, isChecked: boolean) => {
        const updatedFiles = new Set(props.transformationFilesToUpload)

        const toggleChildren = (node: Node) => {
            if ((node as FileNode).path) {
                const fileNode = node as FileNode
                if (isChecked) {
                    updatedFiles.add(fileNode)
                } else {
                    updatedFiles.delete(fileNode)
                }
            } else {
                Object.values(node as FolderNode).forEach((child) => toggleChildren(child))
            }
        }

        toggleChildren(node)
        props.setTransformationFilesToUpload(Array.from(updatedFiles))
    }

    const isFileChecked = (node: FileNode): boolean => {
        return props.transformationFilesToUpload.some((file) => file.path === node.path)
    }

    const isFolderChecked = (node: FolderNode): boolean => {
        const stack: Node[] = [node]
        let hasCheckedChild = false

        while (stack.length > 0) {
            const currentNode = stack.pop()
            if ((currentNode as FileNode).path) {
                if (props.transformationFilesToUpload.some((file) => file.path === (currentNode as FileNode).path)) {
                    hasCheckedChild = true
                    break
                }
            } else {
                Object.values(currentNode as FolderNode).forEach((child) => stack.push(child))
            }
        }

        return hasCheckedChild
    }

    const renderFileTreeIteratively = (tree: FolderNode) => {
        const stack = Object.keys(tree).map((key) => ({
            node: tree[key],
            name: key,
            depth: 0,
            path: `/${key}`,
        }))
        const output = []

        while (stack.length > 0) {
            const { node, name, depth, path } = stack.pop()!

            const isChecked = (node as FileNode).path
                ? isFileChecked(node as FileNode)
                : isFolderChecked(node as FolderNode)

            if ((node as FileNode).path) {
                output.push(
                    <div
                        key={(node as FileNode).path}
                        style={{ paddingLeft: depth * 25 }}
                        className="d-flex align-items-center"
                    >
                        <Form.Check
                            id={`${(node as FileNode).path}-item`}
                            type="checkbox"
                            checked={isChecked}
                            onChange={(e) => handleFileOnChange(node as FileWithPath, e.target.checked)}
                        />
                        <label
                            htmlFor={`${(node as FileNode).path}-item`}
                            className="form-check-label remotive-font-md ms-1"
                        >
                            {name}
                        </label>
                    </div>
                )
            } else {
                output.push(
                    <div
                        key={path}
                        style={{ paddingLeft: depth * 25, fontWeight: 'bold' }}
                        className="d-flex align-items-center"
                    >
                        <Form.Check
                            id={`${path}-folder`}
                            type="checkbox"
                            checked={isChecked}
                            onChange={(e) => handleFolderOnChange(node as FolderNode, e.target.checked)}
                        />
                        <label htmlFor={`${path}-folder`} className="form-check-label remotive-font-md ms-1">
                            {`${name}/`}
                        </label>
                    </div>
                )

                const children = Object.keys(node as FolderNode).reverse()
                children.forEach((child) => {
                    stack.push({
                        node: (node as FolderNode)[child],
                        name: child,
                        depth: depth + 1,
                        path: `${path}/${child}`,
                    })
                })
            }
        }

        return output
    }

    const scrollableListOfFiles = () => {
        const fileTree = parseFileTreeIteratively(
            props.transformationFilesPreparedForUpload.sort((a, b) =>
                (a.path ?? a.name).localeCompare(b.path ?? b.name)
            )
        )

        return (
            <div
                style={{ maxHeight: '45vh' }}
                className="rounded-3 remotive-primary-10-background border p-2 overflow-y-scroll"
            >
                {renderFileTreeIteratively(fileTree)}
            </div>
        )
    }

    const cancelOrUploadButtons = () => {
        const isUploadDisabled = !props.hasPermissionToUpload || props.transformationFilesToUpload.length <= 0
        return (
            <div className="d-flex flex-row justify-content-center align-items-end">
                <button
                    className={'btn remotive-btn remotive-btn-primary me-4'}
                    onClick={async () => {
                        props.onClose()
                    }}
                >
                    <p className="m-0 lexend-regular">Cancel</p>
                </button>

                {configurationAlreadyExists() ? (
                    <>
                        <>
                            <button
                                disabled={isUploadDisabled}
                                className={'btn remotive-btn remotive-btn-success'}
                                onClick={async () => {
                                    productAnalyticsClient.track(AnalyticsActions.UPLOAD_CONFIGURATION)
                                    setIsDeletingOldFiles(true)
                                    await props.deleteTransformationFolder(getConfigurationIfAlreadyExists()!, true)
                                    setIsDeletingOldFiles(false)
                                    props.onClose()
                                    saveBrokerConfigFiles(
                                        props.userRecordingSessionContext.currentProject,
                                        props.userRecordingSessionContext.currentRecordingSession,
                                        props.transformationFilesToUpload
                                    )
                                }}
                            >
                                <p className="m-0">Upload selected and overwrite</p>
                            </button>
                        </>
                    </>
                ) : (
                    <>
                        <button
                            className={'btn remotive-btn remotive-btn-success'}
                            disabled={isUploadDisabled}
                            onClick={async () => {
                                productAnalyticsClient.track(AnalyticsActions.UPLOAD_CONFIGURATION)
                                await saveBrokerConfigFiles(
                                    props.userRecordingSessionContext.currentProject,
                                    props.userRecordingSessionContext.currentRecordingSession,
                                    props.transformationFilesToUpload
                                )
                            }}
                        >
                            <p className="m-0">Upload selected</p>
                        </button>
                    </>
                )}
            </div>
        )
    }

    const component = () => {
        if (props.transformationFilesPreparedForUpload.length <= 0) {
            return <></>
        }

        return (
            <Modal
                size="lg"
                className="lexend-regular"
                centered
                show={props.show}
                contentClassName="border-0 shadow-sm"
                onHide={() => props.onClose()}
            >
                <Modal.Header
                    className="border-0"
                    style={{ marginBottom: -50, zIndex: 1 }}
                    closeButton={!isDeletingOldFiles}
                />
                <Modal.Body>
                    {isDeletingOldFiles ? (
                        <>
                            <div
                                style={{ minHeight: '50vh' }}
                                className="d-flex flex-column justify-content-center align-items-center"
                            >
                                <p>Removing existing files...</p>
                                <Spinner />
                            </div>
                        </>
                    ) : (
                        <>
                            <p className="remotive-font lexend-bold me-4">Select files to upload</p>
                            {scrollableListOfFiles()}

                            {configurationAlreadyExists() && (
                                <TextBanner
                                    className="mt-2"
                                    type={BannerType.WARNING}
                                    boldText="Overwrite"
                                    bodyTextElement={
                                        <>
                                            <p className="m-0 lexend-regular">
                                                This will overwrite your existing transformation folder called{' '}
                                                <b>{getConfigurationIfAlreadyExists()?.name ?? 'Unknown'}</b>
                                            </p>
                                        </>
                                    }
                                />
                            )}
                            <div className="mt-3 mb-2">{cancelOrUploadButtons()}</div>
                        </>
                    )}
                </Modal.Body>
            </Modal>
        )
    }

    return component()
}
