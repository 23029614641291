import { getUserAuthToken } from 'src/utils/broker'
import BrokerApi from './BrokerApi'
import { BrokerCloudContext, BrokerDetails } from './BrokerApi/types'
import { BrokerContainer } from './CloudApi/types'

export const getCloudContext = async (
    brokerContainerToCheck: BrokerContainer
): Promise<BrokerCloudContext | undefined> => {
    const brokerGrpcClient = new BrokerApi(
        {
            brokerUrl: brokerContainerToCheck.url,
            brokerAccessToken: getUserAuthToken(),
            isCloudBroker: () => true,
        } as BrokerDetails,
        () => console.log('unused'),
        () => console.log('unused')
    )
    try {
        const contents = await brokerGrpcClient.downloadAsString('.cloud.context')
        if (contents) {
            const context: BrokerCloudContext = JSON.parse(contents)
            return context
        } else {
            return undefined
        }
    } catch (error: any) {
        console.log('Failed to download cloud context')
        console.log(error)
    }
}
