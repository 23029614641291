type UploadJob = () => Promise<void>

class UploadQueue {
    queue: UploadJob[] = []
    private concurrent: number
    private running: number = 0

    constructor(concurrent: number = 3) {
        this.concurrent = concurrent
    }

    enqueue(job: UploadJob) {
        this.queue.push(job)
        if (this.running < this.concurrent) {
            this.runNext()
        }
    }

    private runNext() {
        while (this.running < this.concurrent && this.queue.length > 0) {
            const job = this.queue.shift()
            if (job) {
                this.running++
                job()
                    .then(() => {
                        this.running--
                        this.runNext()
                    })
                    .catch((error) => {
                        console.error('Upload job failed:', error)
                        this.running--
                        this.runNext()
                    })
            }
        }
    }
}

export default UploadQueue
