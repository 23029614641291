import { AuthenticatedUser } from '../api/CloudApi/types'

export const isLocalOrDevEnvironment = () => {
    return (
        window.location.hostname.startsWith('localhost') ||
        window.location.hostname.startsWith('console.cloud-dev') ||
        window.location.hostname.startsWith('cloud-dev')
    )
}

export const isRemotiveUser = (user: AuthenticatedUser | undefined) => {
    return user ? user.email.endsWith('@remotivelabs.com') : false
}
