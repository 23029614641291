import { FrameEntry, SignalEntry } from 'src/api/CloudApi/types'

export const frameEntryAsSignalEntry = (frameEntry: FrameEntry): SignalEntry => {
    return {
        frameName: frameEntry.name,
        frameNsId: frameEntry.id,
        frameSignalId: frameEntry.id,
        name: frameEntry.name,
        namespace: frameEntry.namespace,
        isSigned: false,
        unit: '',
        comments: frameEntry.comments,
    } as SignalEntry
}

export const signalEntryOnlyForSignalLabel = (frameName: string, namespace: string): SignalEntry => {
    return {
        frameName: frameName,
        frameNsId: 0,
        frameSignalId: 0,
        name: frameName,
        namespace: namespace,
        isSigned: false,
        unit: '',
        comments: '',
    } as SignalEntry
}
