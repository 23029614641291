import { BrokerContainer, RecordingSession } from '../api/CloudApi/types'
import BrokerApi from '../api/BrokerApi'
import { BrokerCloudContext, BrokerDetails } from '../api/BrokerApi/types'
import { useEffect } from 'react'
import { getPlaybackBrokerUrl, getUserAuthToken } from '../utils/broker'
import BrokerAppWrapper from './BrokerAppWrapper'
import { getCloudContext } from 'src/api/CloudBrokerApi'

interface BrokerAppWithMountedRecordingProps {
    isPlaybackAccordionCollapsed: boolean
    brokerContainer?: BrokerContainer
    availableBrokers: Array<BrokerContainer> | undefined
    setBrokerContainer: (brokerContainer: BrokerContainer | undefined) => void
    currentRecordingSession: RecordingSession | undefined
    isActive: boolean
}

export default function BrokerAppWithMountedRecording(props: BrokerAppWithMountedRecordingProps) {
    useEffect(() => {
        const checkIfSomethingIsPlaying = async () => {
            if (props.brokerContainer !== undefined) {
                // Do nothing, it will simply render the broker app
            } else {
                await setBrokerContainerFromLocalStorage()
            }
        }

        if (props.currentRecordingSession !== undefined && props.availableBrokers !== undefined) {
            checkIfSomethingIsPlaying()
        }
    }, [props.currentRecordingSession, props.availableBrokers])

    

    const setBrokerContainerFromLocalStorage = async () => {
        const brokerUrl = getPlaybackBrokerUrl()
        const brokerContainer = (props.availableBrokers ?? []).find((it) => it.url === brokerUrl)
        if (brokerContainer !== undefined) {
            const isMounted = await isRecordingMounted(brokerContainer)
            if (isMounted) {
                props.setBrokerContainer(brokerContainer)
            } else {
                props.setBrokerContainer(undefined)
            }
        }
    }

    const isRecordingMounted = async (brokerContainerToCheck: BrokerContainer) => {
        const cloudContext = await getCloudContext(brokerContainerToCheck)
        const thisRecordingSessionId = props.currentRecordingSession?.sessionId
        if (cloudContext !== undefined && thisRecordingSessionId !== undefined) {
            return cloudContext.recordingSessionId === thisRecordingSessionId
        }
        return false
    }

    return props.brokerContainer !== undefined ? (
        <BrokerAppWrapper
            isActive={props.isActive}
            isPlaybackAccordionCollapsed={props.isPlaybackAccordionCollapsed}
            brokerContainer={props.brokerContainer}
            setBrokerContainer={props.setBrokerContainer}
        />
    ) : (
        <></>
    )
}
