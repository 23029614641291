export const IS_NEW_USER_PARAM = 'isNewUser'
export const NOTIFY_USER_HAS_ACCOUNT_PARAM = 'notifyUserAlreadyHasAccount'
export const REDIRECT_URL_PARAM = 'redirectUrl'
export const ERROR_PARAM = 'error'
export const TAB_PARAM = 'tab'
export const DASHBOARD_PARAM = 'dashboard'
export const CURRENT_PATH_PARAM = 'path'

//values
export const UNKNOWN_USER_PARAM_VALUE = 'UNKNOWN_USER'
