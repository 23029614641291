import { FrameEntry, Project, RecordingSession, SignalEntry } from '../../../api/CloudApi/types'
import {
    Dashboard,
    MapPanel,
    StorableDashboard,
    StorableFrameEntry,
    StorableMapPanel,
    StorableSignalEntry,
    StorableTimeDistributionPanel,
    StorableTimeSeriesPanel,
    StorableTraceWindowPanel,
    TimeDistributionPanel,
    TimeSeriesPanel,
    TraceWindowPanel,
} from './Types'

export function storeDashboard(recordingSession: RecordingSession, project: Project, dashboard: Dashboard) {
    try {
        const storage = window.sessionStorage
        const storageKey = _dashboardStorageKey(recordingSession, project)
        const storableDashboard = _convertToStorableDashboard(dashboard)
        storage.setItem(storageKey, JSON.stringify(storableDashboard))
    } catch (e: any) {}
}

export function getDashboard(
    recordingSession: RecordingSession,
    project: Project,
    availableFrameEntries: Array<FrameEntry>
): Dashboard | undefined {
    const storage = window.sessionStorage
    const storageKey = _dashboardStorageKey(recordingSession, project)
    const storableDashboard = storage.getItem(storageKey)
    if (storableDashboard !== null) {
        try {
            const parsedStorableDashboard: StorableDashboard = JSON.parse(storableDashboard)
            const dashboard = _convertToDashboard(parsedStorableDashboard, availableFrameEntries)
            return dashboard
        } catch (e: any) {
            console.warn('Failed to parse StorableDashboard, removing the stored data for this key')
            storage.removeItem(storageKey)
        }
    }
    return undefined
}

export function convertToStorableDashboard(dashboard: Dashboard): StorableDashboard {
    return _convertToStorableDashboard(dashboard)
}

export function convertToDashboard(
    storableDashboard: StorableDashboard,
    availableFrameEntries: Array<FrameEntry>
): Dashboard {
    return _convertToDashboard(storableDashboard, availableFrameEntries)
}

const _dashboardStorageKey = (recordingSession: RecordingSession, project: Project) => {
    return `analytics-dashboard-${project.uid}-${recordingSession.sessionId}`
}

// *************************************
// *** Stored dashboard => dashboard ***
// *************************************

const _convertToDashboard = (
    storableDashboard: StorableDashboard,
    availableFrameEntries: Array<FrameEntry>
): Dashboard => {
    return {
        timeSeriesPanels: (storableDashboard.timeSeriesPanels ?? []).map((it) =>
            _convertToTimeSeriesPanel(it, availableFrameEntries)
        ),
        traceWindowPanels: (storableDashboard.traceWindowPanels ?? []).map((it) =>
            _convertToTraceWindowPanel(it, availableFrameEntries)
        ),
        timeDistributionPanels: (storableDashboard.timeDistributionPanels ?? []).map((it) =>
            _convertToTimeDistributionPanel(it, availableFrameEntries)
        ),
        mapPanels: (storableDashboard.mapPanels ?? []).map((it) => _convertToMapPanel(it, availableFrameEntries)),
    }
}

const _convertToTraceWindowPanel = (
    storedTraceWindowPanel: StorableTraceWindowPanel,
    availableFrameEntries: Array<FrameEntry>
): TraceWindowPanel => {
    return {
        panelKey: storedTraceWindowPanel.panelKey,
        selectedSignals: storedTraceWindowPanel.selectedSignals
            .map((it) => _convertToSignalEntry(it, availableFrameEntries))
            .filter((it) => it !== undefined) as Array<SignalEntry>,
    }
}

const _convertToTimeSeriesPanel = (
    storedTimeSeriesPanel: StorableTimeSeriesPanel,
    availableFrameEntries: Array<FrameEntry>
): TimeSeriesPanel => {
    return {
        panelKey: storedTimeSeriesPanel.panelKey,
        graphSettings: storedTimeSeriesPanel.graphSettings,
        selectedSignals: storedTimeSeriesPanel.selectedSignals
            .map((it) => _convertToSignalEntry(it, availableFrameEntries))
            .filter((it) => it !== undefined) as Array<SignalEntry>,
        hiddenSignals: storedTimeSeriesPanel.hiddenSignals
            .map((it) => _convertToSignalEntry(it, availableFrameEntries))
            .filter((it) => it !== undefined) as Array<SignalEntry>,
    }
}

const _convertToTimeDistributionPanel = (
    storedTimeDistributionPanel: StorableTimeDistributionPanel,
    availableFrameEntries: Array<FrameEntry>
): TimeDistributionPanel => {
    return {
        panelKey: storedTimeDistributionPanel.panelKey,
        selectedFrame: _convertToFrameEntry(storedTimeDistributionPanel.selectedFrame, availableFrameEntries),
    }
}

const _convertToMapPanel = (storedMapPanel: StorableMapPanel, availableFrameEntries: Array<FrameEntry>): MapPanel => {
    return {
        panelKey: storedMapPanel.panelKey,
        latitudeSignal: _convertToSignalEntry(storedMapPanel.latitudeSignal, availableFrameEntries),
        longitudeSignal: _convertToSignalEntry(storedMapPanel.longitudeSignal, availableFrameEntries),
    }
}

const _convertToSignalEntry = (
    storedSignalEntry: StorableSignalEntry | undefined,
    availableFrameEntries: Array<FrameEntry>
): SignalEntry | undefined => {
    if (storedSignalEntry === undefined) {
        return undefined
    }
    return availableFrameEntries
        .flatMap((it) => it.signals)
        .find(
            (it) =>
                it.name === storedSignalEntry.name &&
                it.frameName === storedSignalEntry.frameName &&
                it.namespace === storedSignalEntry.namespace
        )
}

const _convertToFrameEntry = (
    storedFrameEntry: StorableFrameEntry | undefined,
    availableFrameEntries: Array<FrameEntry>
): FrameEntry | undefined => {
    if (storedFrameEntry === undefined) {
        return undefined
    }
    return availableFrameEntries.find(
        (it) => it.name === storedFrameEntry.name && it.namespace === storedFrameEntry.namespace
    )
}

// *************************************
// *** Dashboard => Stored dashboard ***
// *************************************
const _convertToStorableDashboard = (dashboard: Dashboard): StorableDashboard => {
    return {
        timeSeriesPanels: (dashboard.timeSeriesPanels ?? []).map((it) => _convertToStoredTimeSeriesPanel(it)),
        timeDistributionPanels: (dashboard.timeDistributionPanels ?? []).map((it) =>
            _convertToStoredTimeDistributionPnel(it)
        ),
        mapPanels: (dashboard.mapPanels ?? []).map((it) => _convertToStoredMapPanel(it)),
        traceWindowPanels: (dashboard.traceWindowPanels ?? []).map((it) => _convertToStoredTraceWindowPanel(it))
    }
}

const _convertToStoredTimeSeriesPanel = (timeSeriesPanel: TimeSeriesPanel): StorableTimeSeriesPanel => {
    return {
        graphSettings: timeSeriesPanel.graphSettings,
        panelKey: timeSeriesPanel.panelKey,
        selectedSignals: timeSeriesPanel.selectedSignals.map((it) => _convertToStoredSignalEntry(it)),
        hiddenSignals: timeSeriesPanel.hiddenSignals.map((it) => _convertToStoredSignalEntry(it)),
    }
}

const _convertToStoredTraceWindowPanel = (traceWindowPanel: TraceWindowPanel): StorableTraceWindowPanel => {
    return {
        panelKey: traceWindowPanel.panelKey,
        selectedSignals: traceWindowPanel.selectedSignals.map((it) => _convertToStoredSignalEntry(it)),
    }
}

const _convertToStoredTimeDistributionPnel = (
    timeDistributionPanel: TimeDistributionPanel
): StorableTimeDistributionPanel => {
    return {
        panelKey: timeDistributionPanel.panelKey,
        selectedFrame: _convertToNullableStoredFrameEntry(timeDistributionPanel.selectedFrame),
    }
}

const _convertToStoredMapPanel = (mapPanel: MapPanel): StorableMapPanel => {
    return {
        panelKey: mapPanel.panelKey,
        latitudeSignal: _convertToNullableStoredSignalEntry(mapPanel.latitudeSignal),
        longitudeSignal: _convertToNullableStoredSignalEntry(mapPanel.longitudeSignal),
    }
}

const _convertToStoredSignalEntry = (signalEntry: SignalEntry): StorableSignalEntry => {
    return {
        name: signalEntry.name,
        frameName: signalEntry.frameName,
        namespace: signalEntry.namespace,
    }
}

const _convertToNullableStoredSignalEntry = (signalEntry: SignalEntry | undefined): StorableSignalEntry | undefined => {
    if (signalEntry === undefined) {
        return undefined
    }
    return _convertToStoredSignalEntry(signalEntry)
}

const _convertToNullableStoredFrameEntry = (frameEntry: FrameEntry | undefined): StorableFrameEntry | undefined => {
    if (frameEntry === undefined) {
        return undefined
    }
    return {
        name: frameEntry.name,
        namespace: frameEntry.namespace,
    }
}
