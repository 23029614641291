import React, { useEffect, useState } from 'react'
import { Modal, Button, Form, InputGroup } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

interface CreateFolderModalProperties {
    show: boolean
    text: string
    title: string
    handleCloseFunction: () => void
    createFolderFunction: (folderName: string) => void
}

function CreateFolderModal(props: CreateFolderModalProperties) {
    const [folderName, setFolderName] = useState('')

    const closeModal = () => {
        props.handleCloseFunction()
    }

    useEffect(() => {
        if (props.show) {
            setFolderName('')
        }
    }, [props.show])

    const createFolder = () => {
        props.handleCloseFunction()
        props.createFolderFunction(folderName)
    }

    return (
        <>
            <Modal show={props.show} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Create folder</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <InputGroup size="sm" hasValidation={false}>
                                <InputGroup.Text className={`remotive-font-md bg-transparent border-0 w-25`}>
                                    Name
                                </InputGroup.Text>
                                <Form.Control
                                    className="remotive-font-sm w-75"
                                    type="text"
                                    placeholder="Enter folder name"
                                    value={folderName}
                                    onChange={(e) => setFolderName(e.target.value)}
                                />
                            </InputGroup>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button className={'remotive-btn-primary'} onClick={() => createFolder()}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CreateFolderModal
