import { ReactElement, ReactNode } from 'react'
import { Card } from 'react-bootstrap'

interface RLCardProps {
    body: JSX.Element
    minHeight?: number
    bottomMargin?: 'mb-0' | 'mb-1' | 'mb-2' | 'mb-3' | 'mb-4' | 'mb-5'
    topMargin?: 'mt-0' | 'mt-1' | 'mt-2' | 'mt-3' | 'mt-4' | 'mt-5'
}

export default function RLCard(props: RLCardProps) {
    return (
        <Card
            className={`shadow-sm rounded-4 border-0 ${props.bottomMargin ?? 'mb-4'} ${
                props.topMargin ?? 'mt-4'
            }`}
            style={{ minHeight: props.minHeight ?? 400 }}
        >
            {props.body}
        </Card>
    )
}
