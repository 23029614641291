import { ChecklistRounded, CloseRounded, DeleteRounded, DownloadRounded, LibraryAddCheckRounded } from '@mui/icons-material'
import { FileObject } from 'src/api/CloudApi/types'

interface FileObjectToolbar {
    selectedFiles: Set<FileObject>
    onSelectAll: () => void
    onReset: Function
    onDeleteSelected: Function
    onDownloadSelected: Function
}

export function FileObjectToolbar(props: FileObjectToolbar) {
    const toolbarButton = (icon: JSX.Element, text: string, onClick: Function) => {
        return (
            <button
                className="btn remotive-btn-sm remotive-btn-no-bg border-0 d-flex align-items-center"
                disabled={props.selectedFiles.size <= 0}
                onClick={() => onClick()}
            >
                <div>{icon}</div>
                <p className="m-0 ms-1">{text}</p>
            </button>
        )
    }

    const component = () => {
        return (
            <>
                <div className="remotive-primary-10-background rounded-4">
                    <div className="d-flex flex-row align-items-center">
                        <button
                            className="btn remotive-btn-sm remotive-btn-primary border-0 d-flex align-items-center py-1 me-4 ms-2"
                            onClick={() => props.onSelectAll()}
                        >
                            <div>
                                
                            </div>
                            <p className="m-0">Select all</p>
                        </button>

                        {props.selectedFiles.size > 0 && (
                            <p className="m-0 remotive-font-sm">{props.selectedFiles.size} selected</p>
                        )}

                        {toolbarButton(
                            <>
                                <DeleteRounded sx={{ fontSize: 18 }} />
                            </>,
                            'Delete',
                            props.onDeleteSelected
                        )}
                        {props.selectedFiles.size > 0 && (
                            <button
                                className="btn remotive-btn-sm remotive-btn-no-bg border-0 d-flex align-items-center"
                                onClick={() => props.onReset()}
                            >
                                <div>
                                    <CloseRounded sx={{ fontSize: 20 }} />
                                </div>
                            </button>
                        )}
                    </div>
                </div>
            </>
        )
    }

    return component()
}
